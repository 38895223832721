import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_HEADER = gql`
  query chernobylHeader($miniapp: String!) {
    chernobylHeader(miniapp: $miniapp) {
      title
      subtitle
      logoUrl
      prefix
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_HEADER,
  queryType: 'query',
  baseQueryKey: 'chernobylHeader',
  paginate: false,
  keepPreviousData: true,
});
