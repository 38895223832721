function isProduction() {
  if (process.env?.TARGET_IS_PRODUCTION) return true;
  return !/staging|localhost/i.test(window.location.href);
}

function isHcc() {
  if (process.env?.TARGET_IS_HCC) return true;
  return /botmd\.sg/i.test(window.location.href);
}

const DOMAIN = isHcc() ? 'sg' : 'io';

const GQL_ENDPOINT = isProduction()
  ? `https://athena.production.botmd.${DOMAIN}/gql/`
  : `https://athena.staging.botmd.${DOMAIN}/gql/`;

// const GQL_ENDPOINT = 'http://localhost:9005/gql/';
// const GQL_ENDPOINT = 'https://athena.staging.botmd.io/gql/'

export { GQL_ENDPOINT }; // eslint-disable-line import/prefer-default-export
