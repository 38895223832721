import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes, useLocation } from 'react-router-dom';
import {
  ApiProvider,
  AuthProvider,
  ThemeProvider,
  useAuth,
} from '@fivehealth/botero';
import { CookiesProvider, useCookies } from 'react-cookie';
import DocumentSearch from './components/DocumentSearch';
import RecordSearch from './components/RecordSearch';
import { GQL_ENDPOINT } from './Config';
import { AppDataProvider } from './context/AppDataContext';
import theme from './theme';

interface QueryMap {
  [key: string]: any;
}

const apiQueryCtx = require.context('./api/queries', true, /.ts$/);
const queryMapping = apiQueryCtx
  .keys()
  .reduce((acc: QueryMap, path: string) => {
    const filename = path.split('./').pop();
    if (filename) {
      const key = filename.split('.ts');
      if (key.length) {
        return {
          ...acc,
          [key[0]]: apiQueryCtx(path).default,
        };
      }
    }
    return acc;
  }, {});

const onUnhandledError = () => <h3> There was an error. </h3>;

function AppRouter() {
  const { login, authState } = useAuth();
  const { search } = useLocation();

  const [cookies] = useCookies(['secure_token']);

  const searchParams = new URLSearchParams(search);

  const token = searchParams.get('x-session') || cookies.secure_token;
  const query = searchParams.get('query') || '';
  const miniapp = searchParams.get('miniapp')!;
  const documentAnswerId = searchParams.get('document_answer_id')!;

  useEffect(() => {
    if (token && !authState.authenticated) {
      login({ token });
    }
  }, [token]);

  return (
    <Routes>
      <Route
        path="/document"
        element={
          <DocumentSearch
            query={query}
            miniapp={miniapp}
            documentAnswerId={documentAnswerId}
          />
        }
      />
      <Route
        path="/table"
        element={<RecordSearch query={query} miniapp={miniapp} />}
      />
    </Routes>
  );
}

function App() {
  const numOfHours = 3;
  const queryDefaultStaleTime = 1000 * 60 * 60 * numOfHours;
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnMount: false,
        refetchOnReconnect: false,
        staleTime: queryDefaultStaleTime,
      },
    },
  });

  return (
    <AuthProvider>
      <CookiesProvider>
        <AppDataProvider>
          <ApiProvider endpoint={GQL_ENDPOINT} queryMapping={queryMapping}>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider theme={theme}>
                <ErrorBoundary fallbackRender={onUnhandledError}>
                  <AppRouter />
                </ErrorBoundary>
              </ThemeProvider>
            </QueryClientProvider>
          </ApiProvider>
        </AppDataProvider>
      </CookiesProvider>
    </AuthProvider>
  );
}

export default App;
