import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FAIcon, Flex, InputField, useDebounce } from '@fivehealth/botero';
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';

interface SearchBarProps {
  placeholder: string;
  debounce: number;
  defaultValue: string;
  onChange: (e: string) => void;
}

const SubContainer = styled(Flex)`
  color: #363636;
  justify-content: flex-start;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  flex-grow: 1;
`;

const StyledInput = styled(InputField)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 40px;
  border: 1px solid #d5d7de;
  border-radius: 8px;
  &:focus {
    outline: none !important;
    box-shadow: 0 0 0 0.1em rgb(62 142 208 / 25%);
  }
`;

const StyledIcon = styled(FAIcon)`
  font-size: 16px;
  color: ${(props) => (props.active ? '#4a4a4a' : '#dbdbdb')};
  display: flex;
  align-items: center;
  text-align: center;
`;

function DebouncedInput(props: SearchBarProps) {
  const [active, setActive] = useState(false);
  const [value, setValue] = useState(props.defaultValue);
  const debouncedValue = useDebounce(value, props.debounce);

  useEffect(() => {
    props.onChange(value);
  }, [debouncedValue]);

  // Do once on defaultValue, if it is not empty
  useEffect(() => {
    if (props.defaultValue) {
      props.onChange(props.defaultValue);
    }
  }, []);

  return (
    <StyledInput
      width="100%"
      value={value}
      placeholder={props.placeholder}
      onChange={(e) => setValue(e.target.value)}
      onFocus={() => setActive(true)}
      onBlur={() => setActive(false)}
      startIcon={<StyledIcon icon={faSearch} active={active ? 1 : 0} />}
      endIcon={
        <StyledIcon
          icon={faTimes}
          active={active ? 1 : 0}
          onClick={(e) => {
            e.preventDefault();
            setValue('');
          }}
        />
      }
    />
  );
}

function SearchBar(props: SearchBarProps) {
  return (
    <SubContainer>
      <DebouncedInput
        debounce={props.debounce}
        placeholder={props.placeholder}
        onChange={props.onChange}
        defaultValue={props.defaultValue}
      />
    </SubContainer>
  );
}

export default SearchBar;
