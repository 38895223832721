import { useState } from 'react';
import styled from 'styled-components';
import { Flex, useAuth } from '@fivehealth/botero';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { useCookies } from 'react-cookie';
import { useAppData } from '../context/AppDataContext';
import { Header } from './Header';
import Highlights from './Highlights';
import SearchBar from './SearchBar';
import MiniAppContainer from './MiniAppContainer';
import LinkedIcon from './LinkedIcon';
import { SearchHint, Prefix } from './Common';
import {
  MessageBox,
  MessageTitle,
  MessageSubtitle,
  HelpSVG,
} from './MessageBox';

interface IDocumentSearchInput {
  query: string;
  miniapp: string;
  documentAnswerId: string;
}

const DocumentHeader = styled(Flex)`
  background-color: #ffffff;
  border-bottom: 1px solid #d5d7de;
`;

function DocumentSearch(props: IDocumentSearchInput) {
  const { token } = useAuth();
  const { documentSearch, header } = useAppData();

  const [searchQuery, setSearchQuery] = useState(props.query);
  const [error, setError] = useState<Error | null>(null);

  // backward compatibility if secure_token is not set on old builds
  const [cookies] = useCookies(['secure_token']);
  const secureToken = cookies.secure_token;

  return (
    <MiniAppContainer>
      <Header miniapp={props.miniapp} />
      <SearchBar
        defaultValue={props.query}
        debounce={1000}
        placeholder="Type here to start searching"
        onChange={(q) => setSearchQuery(q)}
      />
      <DocumentHeader>
        <LinkedIcon
          redirectLink={
            secureToken
              ? `/table/?miniapp=${props.miniapp}`
              : `/table/?miniapp=${props.miniapp}&x-session=${token}`
          }
          icon={faChevronLeft}
          style={{
            fontWeight: 900,
            color: '#256BF7',
            paddingLeft: '16px',
            paddingTop: '8px',
            paddingBottom: '8px',
          }}
        />
        <Prefix>{header?.prefix}</Prefix>
      </DocumentHeader>
      {searchQuery &&
        !error &&
        (documentSearch?.results ? (
          <SearchHint>
            <span
              style={{ fontWeight: 700 }}
            >{`${documentSearch?.results?.length} results`}</span>
            &nbsp;{`found for “${searchQuery}”`}
          </SearchHint>
        ) : (
          <SearchHint>
            <span style={{ fontWeight: 700 }}>Loading ...</span>
          </SearchHint>
        ))}
      <Highlights {...props} query={searchQuery} handleError={setError} />
      {!searchQuery && !error && (
        <MessageBox>
          <HelpSVG />
          <MessageTitle>What can we help you find today?</MessageTitle>
          <MessageSubtitle>
            Input keywords above to search within this document
          </MessageSubtitle>
        </MessageBox>
      )}
      {error && (
        <MessageBox>
          <HelpSVG />
          <MessageTitle>Oh no, there was an error!</MessageTitle>
          <MessageSubtitle>
            Something went wrong. Please try again. If the issue persists,
            please reach out to our customer support.
          </MessageSubtitle>
        </MessageBox>
      )}
    </MiniAppContainer>
  );
}

export default DocumentSearch;
