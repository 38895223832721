import { Text } from '@fivehealth/botero';
import styled from 'styled-components';

const SearchHint = styled(Text)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  letter-spacing: -0.08px;
  color: #111824;
  flex: none;
  flex-grow: 0;
  background-color: #ffffff;
  margin: auto;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const Prefix = styled(Text)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #111824;
  background-color: #ffffff;
  margin: auto;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export { Prefix, SearchHint };
