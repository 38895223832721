import { useEffect } from 'react';
import { Box, Flex, Text, useApi, useAuth } from '@fivehealth/botero';
import styled from 'styled-components';
import { useAppData } from '../context/AppDataContext';

const Subtitle = styled(Text)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #697481;
`;

const HeaderBox = styled(Box)`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const PlaceholderHeader = styled(Box)`
  padding-top: 8px;
  padding-bottom: 0px;
  min-height: 100px;
`;

const Header = ({ miniapp }) => {
  const {
    queries: { useChernobylHeader },
  } = useApi({
    queries: ['useChernobylHeader'],
  });

  const { authState } = useAuth();

  const { header, setHeader } = useAppData();

  useChernobylHeader({
    enabled: authState.authenticated,
    staleTime: Infinity,
    variables: {
      miniapp,
    },
    onSuccess: ({ data }) => {
      if (data?.chernobylHeader) {
        setHeader(data.chernobylHeader);
      }
    },
  });

  // set title as page title
  useEffect(() => {
    document.title = header?.title;
  }, [header]);

  return header ? (
    <HeaderBox>
      <Flex alignItems="center" textAlign="center" flexDirection="column">
        {header?.logoUrl && <Box as="img" src={header.logoUrl} height={64} />}
        <Subtitle py={1}>{header?.subtitle}</Subtitle>
      </Flex>
    </HeaderBox>
  ) : (
    <PlaceholderHeader />
  );
};

export { Header, PlaceholderHeader };
