import styled from 'styled-components';
import { Text } from '@fivehealth/botero';

const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  height: 220px;

  position: absolute;
  left: 4.1%;
  right: 4.1%;
  top: 51.66%;
  bottom: 23.7%;
  background: #ffffff;
`;

const MessageTitle = styled(Text)`
  width: 150px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.08px;
  color: #111824;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const MessageSubtitle = styled(Text)`
  width: 296px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.08px;
  color: #697481;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const SorrySVG = styled.div`
  width: 72px;
  height: 72px;
  background: url(/sorry.svg);
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const HelpSVG = styled.div`
  width: 72px;
  height: 72px;
  background: url(/help.svg);
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export { MessageBox, MessageTitle, MessageSubtitle, SorrySVG, HelpSVG };
