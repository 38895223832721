import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { DocumentSearchObject } from '../../types';

export const GRAPHQL_DOCUMENT_DOCUMENT_SEARCH = gql`
  query chernobylDocumentSearch(
    $query: String!
    $miniapp: String!
    $documentAnswerId: String!
  ) {
    chernobylDocumentSearch(
      query: $query
      miniapp: $miniapp
      documentAnswerId: $documentAnswerId
    ) {
      documentMetadata {
        documentUrl
      }
      results {
        id
        pageNo
        pageTexts
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_DOCUMENT_SEARCH,
  queryType: 'query',
  baseQueryKey: 'chernobylDocumentSearch',
  keepPreviousData: true,
  select: ({
    chernobylDocumentSearch,
  }: {
    chernobylDocumentSearch: DocumentSearchObject;
  }) => chernobylDocumentSearch,
});
