import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  DocumentSearchObject,
  RecordSearchObject,
  HeaderObject,
} from '../types';

const initialState: {
  documentSearch: DocumentSearchObject | null;
  recordSearch: RecordSearchObject | null;
  header: HeaderObject | null;
  setDocumentSearch: (value: DocumentSearchObject | null) => void;
  setRecordSearch: (value: RecordSearchObject | null) => void;
  setHeader: (value: HeaderObject | null) => void;
} = {
  documentSearch: null,
  recordSearch: null,
  header: null,
  setDocumentSearch: (value) => {},
  setRecordSearch: (value) => {},
  setHeader: (value) => {},
};

const AppContext = createContext(initialState);

export function AppDataProvider({ children }: { children: React.ReactNode }) {
  const [appState, setAppState] = useState(initialState);

  const setDocumentSearch = useCallback(
    (value: DocumentSearchObject | null) => {
      setAppState({
        ...appState,
        documentSearch: value,
      });
    },
    [appState]
  );

  const setRecordSearch = useCallback(
    (value: RecordSearchObject | null) => {
      setAppState({
        ...appState,
        recordSearch: value,
      });
    },
    [appState]
  );

  const setHeader = useCallback(
    (value: HeaderObject | null) => {
      setAppState({
        ...appState,
        header: value,
      });
    },
    [appState]
  );

  const contextValue = useMemo(
    () => ({
      ...appState,
      setDocumentSearch,
      setRecordSearch,
      setHeader,
    }),
    [appState, setDocumentSearch, setRecordSearch, setHeader]
  );

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}

export const useAppData = () => useContext(AppContext);
