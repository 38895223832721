import { get } from 'lodash';
import styled from 'styled-components';
import { Box, Flex, Text, useApi, useAuth } from '@fivehealth/botero';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { spacify } from '../AppUtils';
import { useAppData } from '../context/AppDataContext';
import { DocumentSearchObject, DocumentSearchResult } from '../types';
import LinkedIcon from './LinkedIcon';

interface HighlightProps {
  query: string;
  miniapp: string;
  documentAnswerId: string;
  handleError: (value: Error | null) => void;
}

const SubContainer = styled(Flex)`
  border-top: 1px solid #ededed;
  color: #363636;
  justify-content: flex-start;
  padding: 0 0;
`;
const PageText = styled(Text)`
  word-wrap: break-word;
  color: #111824;
  em {
    font-style: italic;
    font-weight: bold;
    text-transform: uppercase;
  }
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
`;

const PageNo = styled(Text)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #697481;
  margin-right: auto;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 16px;
`;

const Highlights: React.FC<HighlightProps> = ({
  query,
  miniapp,
  documentAnswerId,
  handleError,
}) => {
  const { authState } = useAuth();
  const { setDocumentSearch } = useAppData();

  const {
    queries: { useChernobylDocumentSearch },
  } = useApi({
    queries: ['useChernobylDocumentSearch'],
  });

  const { data } = useChernobylDocumentSearch({
    enabled: authState.authenticated,
    staleTime: Infinity,
    variables: {
      query,
      miniapp,
      documentAnswerId,
    },
    // eslint-disable-next-line @typescript-eslint/no-shadow
    onSuccess: ({ data }: { data: DocumentSearchObject }) => {
      if (data?.results === undefined) {
        handleError(Error());
      } else {
        setDocumentSearch(data);
      }
    },
    onError: (error: Error) => handleError(error),
  });

  const results: DocumentSearchResult[] = get(data, 'results', []);
  const documentUrl = get(data, 'documentMetadata.documentUrl', '');

  return (
    !!results && (
      <div>
        {results.map((item) => (
          <SubContainer key={item.id}>
            <Box backgroundColor="#FFFFFF" width="100%">
              <Flex backgroundColor="#F4F6F8">
                <PageNo>Page {item.pageNo}</PageNo>
                <LinkedIcon
                  redirectLink={`${documentUrl}#page=${item.pageNo}`} // TODO: NEED TO SEND SESSION TOKEN IN HEADER/BODY
                  icon={faChevronRight}
                  style={{
                    fontWeight: 400,
                    paddingRight: '16px',
                    paddingTop: '4px',
                    paddingBottom: '4px',
                  }}
                />
              </Flex>
              {item.pageTexts.map((pageText, i) => (
                <PageText
                  key={`${item.id}_${i}`}
                  dangerouslySetInnerHTML={{ __html: spacify(pageText) }}
                />
              ))}
            </Box>
          </SubContainer>
        ))}
      </div>
    )
  );
};

export default Highlights;
