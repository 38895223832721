import { get, flatten } from 'lodash';
import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import {
  AccordionWidget,
  FAIcon,
  Flex,
  Text,
  useApi,
  useAuth,
} from '@fivehealth/botero';
import {
  faAngleDown,
  faAngleUp,
  faFileAlt,
  faSearch,
} from '@fortawesome/pro-regular-svg-icons';
import { boldKeys, spacify, telephone, urlLinks, email } from '../AppUtils';
import { useAppData } from '../context/AppDataContext';
import { RecordSearchObject, RecordSearchResult } from '../types';
import {
  MessageBox,
  MessageTitle,
  MessageSubtitle,
  SorrySVG,
} from './MessageBox';
import LinkedIcon from './LinkedIcon';
import TableLoader from './TableLoader';
import { SearchHint } from './Common';

interface TableProps {
  query: string;
  miniapp: string;
  handleError: (value: Error | null) => void;
  searchQueryError: Error | null;
}

type RecordWidget = RecordSearchResult & { isOpen: boolean };

const Content = styled(Text)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #111824;
  .breakAfter {
    content: '';
    display: block;
    margin-bottom: 8px;
  }
  em {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #111824;
    font-weight: 700;
    text-transform: capitalize;
  }
  a {
    text-transform: none; // Resetting text-transform for links
  }
  padding-bottom: 8px;
`;

const TableRow = styled(Flex)`
  justify-content: space-between;
  border-top: 1px solid #e8eaed;
`;

const TableHeader = styled(Flex)`
  flex-shrink: 1;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
`;

const TableIcons = styled(Flex)`
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding-top: 1;
  padding-bottom: 1;
  padding-left: 1;
  padding-right: 8px;
`;

const RecordTitle = styled(Text)`
  display: inline;
  font-style: normal;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  color: #111824;
  word-break: break-all;
`;

const RecordSubtitle = styled(Text)`
  font-style: normal;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #697481;
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 0;
`;

const Thumbnail = styled('img')`
  width: 150px;
  padding: 5px;
`;

const Table = ({ pages, query, miniapp }) => {
  // const { authState } = useAuth();

  const recordList: RecordWidget[] = pages.map((o: RecordSearchResult) => ({
    ...o,
    isOpen: false,
  }));

  return (
    <AccordionWidget
      data={recordList}
      alternateBgColor
      width="100%"
      renderHeader={(record: RecordWidget) => (
        <TableRow>
          <TableHeader>
            <RecordTitle>{record.title}</RecordTitle>
            <RecordSubtitle
              dangerouslySetInnerHTML={{
                __html: spacify(email(telephone(urlLinks(record.subtitle)))),
              }}
            />
          </TableHeader>
          <TableIcons>
            {record.ctaUrl && (
              <div
                style={{
                  paddingLeft: '8px',
                  paddingRight: '8px',
                }}
              >
                <a
                  style={{
                    borderRadius: '8px',
                    border: '1px solid #3e8ed0',
                    color: '#3e8ed0',
                    fontSize: '12px',
                    padding: '4px',
                    marginLeft: '8px',
                    // height: 'max-content',
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                  target="_blank"
                  href={record.ctaUrl}
                  rel="noreferrer"
                >
                  {record.ctaText}
                </a>
              </div>
            )}
            {record.hasSearchableDocumentPages === 'true' && (
              <LinkedIcon
                redirectLink={`/document/?miniapp=${miniapp}&document_answer_id=${record.answerId}&query=${query}`}
                icon={faSearch}
                style={{
                  paddingLeft: '8px',
                  paddingRight: '8px',
                }}
              />
            )}
            {record.documentUrl && (
              <LinkedIcon
                redirectLink={`${record.documentUrl}`} // TODO: NEED TO SEND THE SESSION TOKEN IN HEADER OR BODY
                icon={faFileAlt}
                style={{
                  paddingLeft: '8px',
                  paddingRight: '8px',
                }}
              />
            )}
            {record.hasSearchableDocumentPages !== 'true' &&
              !record.documentUrl &&
              !!record.content && (
                <FAIcon
                  icon={record.isOpen ? faAngleUp : faAngleDown}
                  style={{
                    fontWeight: 400,
                    fontSize: 16,
                    paddingRight: '8px',
                    display: 'flex',
                  }}
                />
              )}
          </TableIcons>
        </TableRow>
      )}
      renderBody={(record: RecordWidget) => {
        const content = record.content.trim();
        return (
          <div>
            {!!record.imageUrl && (
              <Thumbnail src={record.imageUrl} alt="" loading="lazy" />
            )}
            {!!content && (
              <Content
                dangerouslySetInnerHTML={{
                  __html: spacify(
                    email(telephone(urlLinks(boldKeys(content))))
                  ),
                }}
              />
            )}
          </div>
        );
      }}
      containerProps={{ border: 0, borderRadius: 0, py: 0, px: 0 }}
      headerProps={{
        border: 1,
        borderRadius: 0,
        width: '100%',
        marginTop: 0,
        py: 0,
      }}
      bodyProps={{ borderRadius: 0, py: 0, pl: 2 }}
    />
  );
};

const RecordTable: React.FC<TableProps> = ({
  query,
  miniapp,
  handleError,
  searchQueryError,
}) => {
  const { authState } = useAuth();
  const { setRecordSearch } = useAppData();
  const [fetchingNewPage, setFetchingNewPage] = useState(false);
  const isFetchingRef = useRef(false);

  const {
    queries: { useChernobylRecordSearch },
  } = useApi({
    queries: ['useChernobylRecordSearch'],
  });

  const {
    data: recordSearch,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useChernobylRecordSearch({
    enabled: authState.authenticated,
    staleTime: Infinity,
    variables: {
      query,
      miniapp,
    },
    onSuccess: ({ data }: { data: RecordSearchObject }) => {
      if (Object.keys(get(data, 'results.pageInfo')).length === 0) {
        handleError(Error());
      } else {
        setRecordSearch(data);
      }
      setFetchingNewPage(false);
      isFetchingRef.current = false;
    },
    onError: (error: Error) => {
      handleError(error);
      isFetchingRef.current = false;
    },
  });

  // load more on scroll to bottom
  useEffect(() => {
    const handleScroll = async (ev: Event): Promise<any> => {
      if (
        window.innerHeight + window.scrollY + 50 >=
        document.body.scrollHeight
      ) {
        if (hasNextPage && !isFetchingRef.current) {
          setFetchingNewPage(true);
          isFetchingRef.current = true;
          await fetchNextPage();
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasNextPage, fetchNextPage]);

  const pages = flatten(get(recordSearch, `results.pages`, []));

  if (isLoading) {
    return (
      <div>
        {query && (
          <SearchHint>
            <span style={{ fontWeight: 700 }}>Loading ...</span>
          </SearchHint>
        )}
        <Table pages={pages} query={query} miniapp={miniapp} />
      </div>
    );
  }

  if (isSuccess) {
    if (pages.length > 0 && !searchQueryError) {
      return (
        <div>
          {query && (
            <SearchHint>
              <span
                style={{ fontWeight: 700 }}
              >{`${pages.length} results`}</span>
              &nbsp;{`found for "${query}"`}
            </SearchHint>
          )}
          <Table pages={pages} query={query} miniapp={miniapp} />
          <Flex
            style={{
              borderTop: '1px solid rgba(0, 0, 0, 0.1)',
              margin: '0px 16px',
              padding: '12px',
              justifyContent: 'center',
            }}
          >
            {!hasNextPage && <Content>No more entries to fetch</Content>}
            {fetchingNewPage && <Content>Fetching new entries...</Content>}
          </Flex>
        </div>
      );
    }
    if (searchQueryError) {
      return (
        <MessageBox>
          <SorrySVG />
          <MessageTitle>Oh no, there was an error!</MessageTitle>
          <MessageSubtitle>
            Something went wrong. Please try again. If the issue persists,
            please reach out to our customer support
          </MessageSubtitle>
        </MessageBox>
      );
    }
    return (
      <MessageBox>
        <SorrySVG />
        <MessageTitle>Sorry, no results found</MessageTitle>
        <MessageSubtitle>
          Please make sure your keywords are spelled correctly and try other
          similar search words
        </MessageSubtitle>
      </MessageBox>
    );
  }
  return <TableLoader />;
};

export default RecordTable;
