export function spacify(value: string): string {
  return value
    .replace(/ {2,}/g, (s) => '&nbsp;'.repeat(s.length))
    .replace(/\n\n/g, '<br/><div class="breakAfter"></div>')
    .replace(/\n/g, '<br/>');
}

export function telephone(value: string): string {
  return value.replace(/(^|\W)(\+?[\d]{8,13})\b/g, "$1<a href='tel:$2'>$2</a>");
}

export function email(value: string): string {
  const regex = /(^|\W)([\w.-]+@[\w.-]+\.[A-Za-z]{2,})(\b|$)/g;
  return value.replace(regex, "$1<a href='mailto:$2'>$2</a>$3");
}

export function boldKeys(value: string): string {
  const agg = [];

  // eslint-disable-next-line no-restricted-syntax
  for (let line of value.split('\n')) {
    const res = line.match(/^(.+?):(.*)$/);
    if (
      res &&
      res.length === 3 &&
      !line.startsWith('<a href') &&
      !line.startsWith('http')
    ) {
      line = `<em>${res[1]}:</em>${res[2]}`;
    }
    agg.push(line);
  }

  return agg.join('\n');
}

export function urlLinks(value: string): string {
  return value.replace(
    /(<a.*?>.*?<\/a>)|(http|https|ftp):\/\/[\w?=&./-;#~%-]+/g,
    (match, p1) => {
      if (p1) {
        return p1;
      }
      return `<a href='${match}'>${match}</a>`;
    }
  );
}
