import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FAIcon } from '@fivehealth/botero';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';

const Anchor = styled.a`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 2px;
  padding-right: 2px;
`;

// taken from viewer
function isStitchLink(url: string) {
  const stitchUrlFormats = [
    'stitch.botmd.sg',
    'stitch.staging.botmd.sg',
    'stitch.production.botmd.sg',
    'stitch-v2.staging.botmd.io',
    'stitch-v3.staging.botmd.io',
    's.botmd.io',
    't.botmd.io',
  ];
  for (let index = 0; index < stitchUrlFormats.length; index += 1) {
    if (url.includes(stitchUrlFormats[index])) {
      return true;
    }
  }
  return false;
}

function LinkedIcon({
  redirectLink,
  icon,
  style = {},
}: {
  redirectLink: string;
  icon: IconDefinition;
  style: any;
}) {
  const [cookies] = useCookies(['secure_token']);
  const { search } = useLocation();

  const handleLinkClick = async (e) => {
    e.preventDefault();

    const searchParams = new URLSearchParams(redirectLink);

    let authHeader = {};
    if (!searchParams.get('x-session')) {
      // add X-Session to header from cookies if not present in query params
      if (cookies.secure_token) {
        authHeader = {
          'X-Session': cookies.secure_token,
        };
      } else {
        // if redirectLink doesnt have x-session in query and secure_token not in cookies, check if x-session in location and add to header
        const locationSearchParams = new URLSearchParams(search);
        const session = locationSearchParams.get('x-session');
        if (session) {
          authHeader = {
            'X-Session': session,
          };
        }
      }
    }

    try {
      const response = await fetch(redirectLink, {
        method: 'GET',
        headers: authHeader,
      });

      if (response.ok) {
        const responseUrl = response.url; // Get the 'Location' header from the response
        if (responseUrl) {
          window.location.href = responseUrl;
        } else {
          window.location.href = e.target.href;
        }
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  };

  return (
    <Anchor
      href={redirectLink}
      onClick={isStitchLink(redirectLink) ? handleLinkClick : null}
    >
      <FAIcon
        style={{
          fontSize: '16px',
          fontWeight: 400,
          fontStyle: 'normal',
          color: '#697481',
          ...style,
        }}
        icon={icon}
        hover={{
          opacity: 0.6,
        }}
      />
    </Anchor>
  );
}

export default LinkedIcon;
