import { gql } from 'graphql-request';
import { get } from 'lodash';
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT_RECORD_SEARCH = gql`
  query chernobylRecordSearch(
    $first: Int
    $offset: Int
    $query: String
    $miniapp: String!
  ) {
    chernobylRecordSearch(query: $query, miniapp: $miniapp) {
      results(first: $first, offset: $offset) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            uid
            title
            subtitle
            content
            ctaText
            ctaUrl
            imageUrl
            documentUrl
            documentPages
            answerId
            hasSearchableDocumentPages
          }
        }
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_RECORD_SEARCH,
  queryType: 'query',
  baseQueryKey: 'chernobylRecordSearch',
  paginate: 'offset',
  select: (data: any) => {
    const uniqueIds: string[] = [];
    const idx = get(data, 'pages', []).length - 1;
    const res = {
      ...data,
      totalCount: get(
        data,
        `pages[${idx}].chernobylRecordSearch.results.totalCount`
      ),
      pageInfo: get(
        data,
        `pages[${idx}].chernobylRecordSearch.results.pageInfo`,
        {}
      ),
      pages: get(data, 'pages', []).map((page: any) =>
        get(page, 'chernobylRecordSearch.results.edges', [])
          .filter(({ node }: { node: any }) => {
            if (!node || !node.uid || uniqueIds.includes(node.uid)) {
              return false;
            }
            uniqueIds.push(node.uid);
            return true;
          })
          .map(({ node }: { node: any }) => node)
      ),
    };
    return {
      results: res,
    };
  },
  getNextPageParam: (
    { chernobylRecordSearch: data }: any = {},
    allPages: any
  ) => {
    const hasNextPage = get(data, 'results.pageInfo.hasNextPage', false);
    if (!hasNextPage) {
      return undefined;
    }
    const currentOffset = allPages.flatMap(({ chernobylRecordSearch }: any) =>
      get(chernobylRecordSearch, 'results.edges', [])
    ).length;
    return currentOffset;
  },
  keepPreviousData: true,
});
