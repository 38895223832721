import styled from 'styled-components';
import { Box, Body } from '@fivehealth/botero';

const Container = styled.div`
  @media screen and (min-width: 1024px) {
    max-width: 960px;
  }
  @media screen and (min-width: 1216px) {
    max-width: 1152px;
  }
  margin: 0 auto;
  flex-grow: 1;
  width: auto;
`;

const StyledBox = styled(Box)`
  background-color: #f8f8f8;
  overflow-x: hidden;
  overflow-y: scroll;
  text-size-adjust: 100%;
`;

function MiniAppContainer(props) {
  return (
    <StyledBox>
      <Body lineHeight={1.5}>
        <Container>{props.children}</Container>
      </Body>
    </StyledBox>
  );
}

export default MiniAppContainer;
