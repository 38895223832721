import { useState } from 'react';

import RecordTable from './RecordTable';
import SearchBar from './SearchBar';
import MiniAppContainer from './MiniAppContainer';
import { Header } from './Header';

interface IRecordSearchInput {
  query: string;
  miniapp: string;
}

function RecordSearch(props: IRecordSearchInput) {
  const [searchQuery, setSearchQuery] = useState(props.query);
  const [error, setError] = useState<Error | null>(null);

  return (
    <MiniAppContainer>
      <Header miniapp={props.miniapp} />
      <SearchBar
        debounce={1000}
        defaultValue={props.query}
        placeholder="Type here to start searching"
        onChange={(q) => {
          setSearchQuery(q);
          setError(null);
        }}
      />
      <RecordTable
        query={searchQuery}
        miniapp={props.miniapp}
        handleError={setError}
        searchQueryError={error}
      />
    </MiniAppContainer>
  );
}

export default RecordSearch;
